import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from '../views/ProfileView'
import SingleProfile from '../views/SingleProfile'
import MessagesView from "../views/MessagesView";
import ChatView from "../views/ChatView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    props: true
  },
  {
    path: '/profile/:id',
    name: 'single',
    component: SingleProfile
  },
  {
    path: "/messages",
    name: "messages",
    component: MessagesView,
  }, {
    path: "/messages/:id",
    name: "chat",
    component: ChatView,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
