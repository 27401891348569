<template>
  <div class="flex flex-wrap flex-col-reverse  py-4">
    <div v-bind="$attrs"
      class="bg-white shadow-md p-4 rounded-lg   mb-4"
      v-for="(post, index) of getPosts"
      :key="post.id"
    >
      <div class="flex items-start mb-3">
        <img
          @click="
            $router.push({ name: 'single', params: { id: post.user_login } })
          "
          class="w-14 h-14 object-cover mr-5 rounded-full cursor-pointer"
          :src="getUsers[post.user_login].avatar"
          alt=""
        />
        <div>
          <p class="text-xl font-bold">
            {{
              getUsers[post.user_login].name +
              " " +
              getUsers[post.user_login].surname
            }}
          </p>
          <p>{{ getDate[index] }}</p>
        </div>
      </div>
      <div>
        <p>{{ post.text }}</p>
        <img v-if="post.image" class="w-full" :src="post.image" alt="" />
        <div
          @click="setLike(index)"
          class="my-2 flex items-center hover:cursor-pointer group"
        >
          <i
            v-if="!post.likes.includes(currentUser)"
            class="fa-regular fa-heart mr-2 group-hover:text-main"
          ></i>
          <i v-else class="fa-solid fa-heart text-main mr-2"></i>
          <span class="font-bold">{{ post.likes.length }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters} from 'vuex'

export default {
  name: 'PostsComp',
  props: {
      setPosts: Array
  },
  data() {
    return {
      users: [],
      currentUser: localStorage.getItem('loggedUser'),
      month: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Ноябрь',
        'Декабрь',
      ]

    }
  },
  async mounted() {
    let res = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/users')
    this.users = res.data

    await this.getAsyncPosts()
  },
  computed: {
    ...mapGetters(['posts']),
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentUser)
        }
        return {}
    },
    getUsers() {
      if(this.users) {
            let arr = this.users.filter(e => this.setPosts.includes(e.email))
            let obj = {}
            arr.forEach(e => obj[e.email] = e)
            return obj
        }
        return {}
    },
    getPosts() {
      if(this.posts) {
            return this.posts.filter((e) => this.setPosts.includes(e.user_login))
        }
        return []
    },
    getDate() {
      if(this.getPosts) {
            return this.getPosts.map(e => {
              let date = new Date(e.createdAt)
              return date.getDate() + ' ' + 
              this.month[date.getMonth()] + ' ' +
              date.getHours() + ":" +
              date.getMinutes()
            })
        }
        return []
    },

  },
  methods: {
    ...mapActions(['getAsyncPosts']),
    async setLike(id) {
      let postID = this.getPosts[id].id;
      if (this.getPosts[id].likes.includes(this.currentUser)) {
        let myIndex = this.getPosts[id].likes.indexOf(this.currentUser);
        this.getPosts[id].likes.splice(myIndex, 1);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.getPosts[id]
        );
      } else {
        console.log(postID);
        this.getPosts[id].likes.push(this.currentUser);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.getPosts[id]
        );
      }
    }
  }
}
</script>
