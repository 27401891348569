<template>
  <div class="bg-slate-100 min-h-screen">
    <div class="container mx-auto">
      <PostsComp class="w-2/3 mx-auto" :setPosts="activeUser.friends"/>
      <div class="fixed right-6 top-5">
        <div
          class="flex items-center justify-between hover:cursor-pointer"
          v-for="user of usersList"
          :key="user.id"
        >
          <div class="flex items-center my-2">
            <img
              class="w-9 h-9 object-cover rounded-full mr-2"
              :src="user.avatar"
              alt=""
            />
            <p class="text-sm font-semibold">
              {{ user.name + " " + user.surname }}
            </p>
          </div>
          <p @click="addToFriends(user.email)" class="ml-2">
            <i class="fa-solid fa-plus"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PostsComp from '@/components/PostsComp.vue'
import axios from 'axios'
export default {
  name: 'HomeView',
  data() {
    return {
      users: [],
      currentUser: localStorage.getItem('loggedUser'),
      

    }
  },
  async mounted() {
    let res = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/users')
    this.users = res.data
  },
  computed: {
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentUser)
        }
        return {}
    },
    usersList() {
      if(this.users) {
            return this.users.filter(e => !this.activeUser.friends.includes(e.email))
           
        }
        return []
    }
  },
  methods: {
    async addToFriends(email) {
      this.activeUser.friends.push(email)
      await axios.put("https://6282500ded9edf7bd882691b.mockapi.io/users/" + this.activeUser.id, this.activeUser)
      this.$router.go()
    }
  },
  components: {
    PostsComp
  }
}
</script>
