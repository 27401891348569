import axios from 'axios'
export default {
    state: {
        posts: null
    },
    getters: {
        posts(state) {
            return state.posts
        }
    },
    mutations: {
        setPosts(state, {data}) {
            state.posts = data
        }
    },
    actions: {
        async getAsyncPosts({commit}) {
            commit('setPosts', await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/posts'))
        }
    }
}