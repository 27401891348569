<template>
   <div class="my-10 h-full">
    <div class="container mx-auto">
      <p class="font-semibold mb-7">
        {{ chatUser.name + " " + chatUser.surname }}
      </p>

      <div class="bg-slate-100 h-full p-5 rounded-lg">
        <div>
          <div v-if="currentChat">
            <div class="flex flex-col justify-end my-3" v-for="text of currentChat.text" :key="text.msg">
             <div class="flex flex-col "
             :class="{ 'items-end': text.from == currentUser }"
             >
                <p
                 class="font-semibold" >
                  {{ text.from }}
                </p>
                <p>
                  {{ text.msg }}
                </p>
             </div>
            </div>
          </div>
          <div v-else><p class="text-center pt-4 font-semibold text-lg"> Сообщений в чате нет</p>
          <p class="text-center pb-4 font-light">Отправьте первое сообщение</p></div>
        </div>
        <form  @submit.prevent="sendMessage" class="flex">
          <input
            v-model="textMessage"
            type="text"
            placeholder="Напишите сообщение"
            class="w-full p-3 rounded-full mx-1 border"
          />
          <button
           
            class="bg-main px-3 ml-3 text-white font-bold rounded-lg"
          >
            Отправить
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChatView",
  data() {
    return {
      users: null,
      messages: null,
      currentUser: localStorage.getItem("loggedUser"),
      curID: this.$route.params.id,
      textMessage: null
    };
  },
  computed: {
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentUser)
        }
        return {}
    },
    chatUser() {
      if (this.users === null) {
        return console.log("...");
      } else {
        return this.users.find((e) => e.id === this.curID);
      }
    },
    currentChat() {
        if(this.messages) {
            return this.messages.find(e => {
                return ((e.personOne == this.currentUser  ||  e.personOne == this.chatUser.email) && 
                (e.personTwo == this.currentUser  ||  e.personTwo == this.chatUser.email))
            })
        } else {
            return null
        }
    }
  },
  async created() {
    ({data: this.users} = await axios.get("https://6282500ded9edf7bd882691b.mockapi.io/users"));
    ({data: this.messages} = await axios.get("https://6282500ded9edf7bd882691b.mockapi.io/messages"))
  },
  methods: {
    async sendMessage(e) {
      if (!this.currentChat) {
        await axios.post(
          "https://6282500ded9edf7bd882691b.mockapi.io/messages",
          {
            personOne: this.currentUser,
            personTwo: this.chatUser.email,
            text: [
              {
                from: this.currentUser,
                msg: this.textMessage
              }
            ]
          }
        )
        this.$router.go()
      } else {
        this.currentChat.text.push(
          {
            from: this.currentUser,
            msg: this.textMessage
          }
          );
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/messages/" +
            this.currentChat.id,
          this.currentChat
        );
      }
      e.target.reset()
    },
  }
};
</script>