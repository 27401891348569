<template>
    <div class="bg-slate-100 min-h-screen">
       <div class="bg-white">
         <div class="container mx-auto">
            <div class="w-full h-80 bg-black rounded-lg overflow-hidden">
                <img class="object-cover h-full w-full" :src="activeUser.background" alt="">
            </div>
            <div class="flex items-center">
                <img class="h-44 rounded-full ring-blue-500 w-44 object-cover ring" :src="activeUser.avatar" alt="">
                <div class="px-4">
                    <h1 class="text-2xl font-bold"> {{ activeUser.name }} {{activeUser.surname }}</h1>
                    <p  class="text-gray-500 font-semibold">друзья: {{activeUser.friends.length}}</p>
                    <div>
                     <div class="flex flex-row-reverse  space-x-reverse -space-x-2 justify-end"> 
                       <img v-for="item of friends" :key="item.id" class="rounded-full object-cover h-10 w-10 ring-2 ring-white" :src="item.avatar">
                     </div>
                    </div>
                </div>
                <div class="ml-auto flex flex-col items-end gap-3">
                  <!-- <button class="px-6 py-3 bg-gray-200 font-semibold rounded-lg text-center"><i class="fa-solid fa-pen"></i> Редактировать профиль</button> -->
                </div>
            </div>
         </div>
       </div>
        <div class="container mx-auto pt-5">
          <div class="flex ">
            <div class="w-1/3 bg-white p-4 rounded-lg self-start flex-shrink-0" >
              <h2 class="text-xl font-bold mb-4">Краткая информация</h2>
              <p>
                Работа:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.working != ''"
                  >{{ activeUser.profileInfo.working }}</span>
                <span v-else class="font-bold">Данные отсутствуют</span>
              </p>
              <p>
                Информация о себе:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.aboutMe != ''"
                  >{{ activeUser.profileInfo.aboutMe }}</span
                >
                <span class="font-bold" v-else>Данные отсутствуют</span>
              </p>
              <p>
                Место проживания:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.city != ''"
                  >{{ activeUser.profileInfo.city }}</span>
                <span class="font-bold" v-else>Данные отсутствуют</span>
              </p>
            </div>
            <div class="flex-grow ml-4 px-5 flex-col">
            <div class="flex flex-wrap flex-col-reverse">
            <div class="bg-white w-full py-4 rounded-lg mb-4 " v-for="(post, index) of userPosts" :key="post.id">
              <div class="flex items-start mb-3 px-4">
                <img class="w-14 h-14 object-cover mr-5 rounded-full" :src="activeUser.avatar" alt="">
                <div>
                  <p class="text-xl font-bold">{{ activeUser.name + " " + activeUser.surname }}</p>
                  <p>{{getDate[index]}}</p>
                </div>
              </div>
              <div>
                <p class="px-4">{{ post.text }}</p>
                <img v-if="post.image" class="w-full" :src="post.image" alt="">
                <div @click="setLike(index)" class="my-2 px-4 flex items-center hover:cursor-pointer group ">
                  <i v-if="!post.likes.includes(currentUser)" class="fa-regular fa-heart mr-2 group-hover:text-main"></i> 
                  <i v-else class="fa-solid fa-heart text-main mr-2"></i> 
                  <span v-if="post.likes.length" class="font-bold">{{ post.likes.length }}</span>
                </div>
              </div>
            </div>
          </div>
              <!-- <div>
                <div class="bg-white mt-5 p-4" v-for="(post, idx) of userPosts" :key="post.id">
                  <img class="h-12 rounded-full ring-blue-500 w-12 object-cover" :src="activeUser.avatar" alt="">
                  <p class="text-base font-bold"> {{ post.user_login}}</p>
                  <p>{{post.text}}</p>
                  <p>Лайков {{post.likes}}</p>
                  <p>Дата {{getDate[idx]}}</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
    </div>
</template>
      
<script>
import axios from 'axios'
export default {
    name: 'ProfileView',
    data() {
    return {
      users: null,
      posts: null,
      currentFriend: this.$route.params.id,
      currentUser: localStorage.getItem('loggedUser'),
      month: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Ноябрь',
        'Декабрь',
      ]

    }
  },
  computed: {
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentFriend)
        }
        return {}
    },
    userPosts() {
      if(this.posts) {
            return this.posts.filter((e) => e.user_login === this.currentFriend)
        }
        return []
    },
    getDate() {
      if(this.userPosts) {
            return this.userPosts.map(e => {
              let date = new Date(e.createdAt)
              return date.getDate() + ' ' + 
              this.month[date.getMonth()] + ' ' +
              date.getHours() + ":" +
              date.getMinutes()
            })
        }
        return []
    },
    friends() {
      return  this.users.filter(e => this.activeUser.friends.includes(e.email))
    }
  },
  async mounted() {
    let res = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/users')
    this.users = res.data

    let posts = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/posts')
    this.posts = posts.data
  },
  methods: {
    async setLike(id) {
      let postID = this.userPosts[id].id;
      if (this.userPosts[id].likes.includes(this.currentUser)) {
        let myIndex = this.userPosts[id].likes.indexOf(this.currentUser);
        this.userPosts[id].likes.splice(myIndex, 1);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.userPosts[id]
        );
      } else {
        console.log(postID);
        this.userPosts[id].likes.push(this.currentUser);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.userPosts[id]
        );
      }
    }
  }
}
</script>