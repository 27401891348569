<template>
  <div id="app">

   <regLogin v-if="!currentUser" />
   <div v-else>
      <div class="fixed flex flex-col border-r py-5 h-full bg-white w-14 left-0 top-0">
        <i
          @click="$router.push({ name: 'profile' })"
          class="
            fa-solid fa-user
            block
            text-center text-2xl
            my-2
            first
            hover:cursor-pointer hover:text-main
          "
        ></i>
        <i
          @click="$router.push({ name: 'home' })"
          class="
            fa-solid fa-house
            block
            text-center text-2xl
            my-2
            hover:cursor-pointer hover:text-main
          "
        ></i>
        <i
          @click="$router.push({ name: 'messages' })"
          class="
            fa-solid fa-message
            block
            text-center text-2xl
            my-2
            hover:cursor-pointer hover:text-main
          "
        ></i>
        <i @click="logoutModal = true" 
        class="fa-solid fa-arrow-right-from-bracket text-center text-2xl block
          mt-auto hover:cursor-pointer hover:text-main"></i>
      </div>
     <router-view />
   </div>
    
    <div v-if="logoutModal" class="fixed overflow-hidden top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50">
      <div class="bg-white w-full lg:w-1/4 shadow-md p-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center flex-col">
       <i 
        class="fa-solid fa-arrow-right-from-bracket text-center text-3xl block
         text-main"></i>
        <h2 class="text-2xl font-semibold text-center mb-6">Ты уходишь?</h2>
        <div class="flex space-x-2">
          <button @click="logoutModal = false"  class="w-1/2 bg-green-500 text-white rounded-md py-2 focus:ring-2">Отмена</button>
          <button @click="logout"  class="w-1/2   bg-main text-white rounded-md py-2 focus:ring-2">Выйти</button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import regLogin from './components/RegLogin.vue'
export default {
  components: {
    regLogin
  },
  data() {
    return {
      currentUser: localStorage.getItem('loggedUser'),
      logoutModal: false
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("loggedUser");
      this.logoutModal = false
      this.$router.go();
    },
  },
  mounted() {
    /* const loggedPath = '/profile'
    if(this.currentUser && this.$route.path !== loggedPath) {
      this.$router.push(loggedPath)
    } */
  }
}
</script>
