<template>
    <div class="bg-slate-100 min-h-screen">
       <div class="bg-white">
         <div class="container mx-auto">
            <div class="w-full h-80 bg-black rounded-lg overflow-hidden">
                <img class="object-cover h-full w-full" :src="activeUser.background" alt="">
            </div>
            <div class="flex items-center">
                <img class="h-44 rounded-full ring-blue-500 w-44 object-cover ring" :src="activeUser.avatar" alt="">
                <div class="px-4">
                    <h1 class="text-2xl font-bold"> {{ activeUser.name }} {{activeUser.surname }}</h1>
                    <p class="text-gray-500 font-semibold">друзья: {{activeUser.friends.length}}</p>
                    <div>
                     <div class="flex flex-row-reverse  space-x-reverse -space-x-2 justify-end"> 
                       <img v-for="item of friends" :key="item.id" class="rounded-full object-cover h-10 w-10 ring-2 ring-white" :src="item.avatar">
                     </div>
                    </div>
                </div>
                <div class="ml-auto flex flex-col items-end gap-3">
                  <button @click="editProfileModal = true" class="px-6 py-3 bg-gray-200 font-semibold rounded-lg text-center"><i class="fa-solid fa-pen"></i> Редактировать профиль</button>
                </div>
            </div>
         </div>
       </div>
        <div class="container mx-auto pt-5">
          <div class="flex ">
            <div class="w-1/3 bg-white p-4 rounded-lg self-start flex-shrink-0" >
              <h2 class="text-xl font-bold mb-4">Краткая информация</h2>
              <p>
                Работа:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.working != ''"
                  >{{ activeUser.profileInfo.working }}</span>
                <span v-else class="font-bold">Данные отсутствуют</span>
              </p>
              <p>
                Информация о себе:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.aboutMe != ''"
                  >{{ activeUser.profileInfo.aboutMe }}</span
                >
                <span class="font-bold" v-else>Данные отсутствуют</span>
              </p>
              <p>
                Место проживания:
                <span
                  class="font-bold"
                  v-if="activeUser.profileInfo.city != ''"
                  >{{ activeUser.profileInfo.city }}</span>
                <span class="font-bold" v-else>Данные отсутствуют</span>
              </p>
            </div>
            <div class="flex-grow ml-4 px-5 flex-col">
              <div class="flex justify-center bg-white p-4 mb-5">
                <img class="h-12 rounded-full ring-blue-500 w-12 object-cover mr-1" :src="activeUser.avatar" alt="">
                <input v-model="newPost.text" class="w-full p-3 rounded-full mx-1 border" type="text" placeholder="Что у вас нового?">
                <input v-model="newPost.image" class="w-full p-3 rounded-full mx-1 border" type="text" placeholder="Загрузите картинку">
            <input v-model="newPost.video" class="w-full p-3 rounded-full mx-1 border" type="text" placeholder="Загрузите видео">
                <button @click="sendPost()" class="bg-main px-3 ml-3 text-white font-bold rounded-lg">Отправить</button>
              </div>
             <PostsComp :setPosts="[currentUser]"/>
            </div>
          </div>
        </div>
        <div v-if="editProfileModal" class="fixed overflow-hidden top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50">
      <div class="bg-white w-full lg:w-1/4 shadow-md p-4 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center flex-col">
       <i 
        class="fa-solid fa-arrow-right-from-bracket text-center text-3xl block
         text-main"></i>
        <h2 class="text-2xl font-semibold text-center mb-6">Ты уходишь?</h2>
        <div class="flex space-x-2">
          <button @click="editProfileModal = false"  class="w-1/2 bg-green-500 text-white rounded-md py-2 focus:ring-2">Отмена</button>
          <button @click="saveProfile"  class="w-1/2   bg-main text-white rounded-md py-2 focus:ring-2">Сохранить</button>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import PostsComp from '@/components/PostsComp.vue'
import axios from 'axios'
export default {
    name: 'ProfileView',
    data() {
    return {
      users: null,
      posts: null,
      comments: [],
        image: null,
        video: null,
      newPost: {
        user_login: localStorage.getItem("loggedUser"),
        text: null,
        likes: [],
      },
      currentUser: localStorage.getItem('loggedUser'),
      editProfileModal: false,
      month: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Ноябрь',
        'Декабрь',
      ]

    }
  },
  computed: {
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentUser)
        }
        return {}
    },
    userPosts() {
      if(this.posts) {
            return this.posts.filter((e) => e.user_login === this.currentUser)
        }
        return []
    },
    getDate() {
      if(this.userPosts) {
            return this.userPosts.map(e => {
              let date = new Date(e.createdAt)
              return date.getDate() + ' ' + 
              this.month[date.getMonth()] + ' ' +
              date.getHours() + ":" +
              date.getMinutes()
            })
        }
        return []
    },
    friends() {
      return  this.users.filter(e => this.activeUser.friends.includes(e.email))
    }
  },
  async mounted() {
    let res = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/users')
    this.users = res.data

    let posts = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/posts')
    this.posts = posts.data
  },
  methods: {
    async setLike(id) {
      let postID = this.userPosts[id].id;
      if (this.userPosts[id].likes.includes(this.currentUser)) {
        let myIndex = this.userPosts[id].likes.indexOf(this.currentUser);
        this.userPosts[id].likes.splice(myIndex, 1);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.userPosts[id]
        );
      } else {
        console.log(postID);
        this.userPosts[id].likes.push(this.currentUser);
        await axios.put(
          "https://6282500ded9edf7bd882691b.mockapi.io/posts/" + postID,
          this.userPosts[id]
        );
      }
    },
    async sendPost() {
      await axios.post(
        "https://6282500ded9edf7bd882691b.mockapi.io/posts",
        this.newPost
      );
      this.$router.go();
    },
    async saveProfile() {
      
    }
  },
  components: {
    PostsComp
  }
}
</script>