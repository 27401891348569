<template>
  <div class="py-44 bg-slate-100">
    <div class="container mx-auto">
      <div class="flex items-center justify-between">
        <div class="w-1/2">
          <img
            class="w-1/2 mb-5"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Facebook_Logo_%282019%29.svg/1200px-Facebook_Logo_%282019%29.svg.png"
            alt=""
          />
          <p class="text-2xl font-medium">Facebook помогает вам всегда оставаться на связи и общаться со своими знакомыми.</p>
        </div>
        <div class="w-1/3 bg-white rounded-xl p-5">
          <div v-if="setForm === 1">
            <input class="w-full p-3 rounded-lg border my-2" placeholder="Электронный адрес" type="text" v-model="login.email" name="email">
            <input v-model="login.password" class="w-full p-3 rounded-lg border my-2" placeholder="Пароль" type="text">
            <button @click="loginUser" class="bg-main p-3 text-xl font-medium mt-2 text-center w-full rounded-lg text-white">Вход</button>
          </div>
          <div v-if="setForm === 2">
            <input class="w-full p-3 rounded-lg border my-2" placeholder="Имя" v-model="registration.name" type="text">
            <input class="w-full p-3 rounded-lg border my-2" placeholder="Фамилия" type="text" v-model="registration.surname">
            <input class="w-full p-3 rounded-lg border my-2"  placeholder="Email" v-model="registration.email" type="text">
            <input class="w-full p-3 rounded-lg border my-2" placeholder="Телефон" type="text" v-model="registration.phone">
            <div class="flex items-center justify-between">
              <div>
                <label class="mr-2" for="">Мужчина</label>
                <input v-model="registration.gender" type="radio" name="gender" value="male">
              </div>
              <div>
                <label class="mr-2" for="">Женщина</label>
                <input v-model="registration.gender" type="radio" name="gender" value="female">
              </div>
            </div>
            <input v-model="registration.password" class="w-full p-3 rounded-lg border my-2" placeholder="Пароль" type="text">
            <button @click="registerUser" class="bg-main p-3 text-xl font-medium mt-2 text-center w-full rounded-lg text-white">Регистрация</button>
          </div>
          <div class="w-full border-b my-2"></div>
          <button @click="setForm = 2" v-if="setForm === 1" class="bg-green-500 p-3 text-xl font-medium text-center w-full rounded-lg text-white">Регистрация</button>
          <button @click="setForm = 1" v-if="setForm === 2" class="bg-green-500 p-3 text-xl font-medium text-center w-full rounded-lg text-white">Вход</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'RegLogin',
    data() {
    return {
      currentUser: {},
      usersData: [],
      setForm: 1,
      registration: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        gender: null,
        password: null,
      },
      login: {
          email: null,
          password: null
      }
    }
  },
  methods: {
        async registerUser() {
            await axios.post('https://6282500ded9edf7bd882691b.mockapi.io/users',this.registration )
        },
        loginUser() {
          let user = this.usersData.find(e => {
            return e.email == this.login.email && e.password == this.login.password
          })
          if (user) {
            localStorage.setItem('loggedUser',  user.email )
            this.currentUser = user
          } 
          this.$router.go()
        }
  },
  async mounted() {
    let res = await axios.get('https://6282500ded9edf7bd882691b.mockapi.io/users')
    this.usersData = res.data
  },
}
</script>