<template>
  <div class="my-10">
    <div class="container mx-auto">
      <div class="w-1/2 mx-auto bg-slate-100 p-5 rounded-lg">
        <h1 class="text-xl font-semibold">Сообщения</h1>
        <div
          class="my-4 flex items-center justify-between"
          v-for="user of friends"
          :key="user.id"
        >
          <div class="flex items-center">
            <img
              class="w-8 h-8 rounded-full object-cover mr-3"
              :src="user.avatar"
              alt=""
            />
            <p>{{ user.name + " " + user.surname }}</p>
          </div>
          <p
            @click="$router.push({ name: 'chat', params: { id: user.id } })"
            class="text-sm font-semibold text-main hover:cursor-pointer"
          >
            Перейти в чат <i class="fa-solid fa-arrow-right"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MessagesView",
  data() {
    return {
      users: null,
      posts: null,
      currentUser: localStorage.getItem("loggedUser")
    };
  },
  computed: {
    activeUser() {
        if(this.users) {
            return this.users.find((e) => e.email === this.currentUser)
        }
        return {}
    },
    friends() {
      if(this.users) {
            let arr = this.users.filter(e => this.activeUser.friends.includes(e.email))
            let obj = {}
            arr.forEach(e => obj[e.email] = e)
            return obj
        }
        return {}
    }
  },
  async created() {
    let res = await axios.get(
      "https://6282500ded9edf7bd882691b.mockapi.io/users"
    );
    this.users = res.data;
  },
  methods: {
    
  }
};
</script>